import React from "react";
import { NavLink } from "react-router-dom";

export default function SideMenu() {
  return (
    <div className="side-menu">
      <ul>
        <li>
          <NavLink
            to="/solutions/"
            end
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Solutions
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/about/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            About us
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/pricing/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Pricing
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/news/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            News
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/privacy/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Privacy
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/terms/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Terms
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
