import React from "react";

export default function Pricing(props) {
  const { onClick } = props;
  return (
    <div className="page-container" onClick={onClick}>
      <div className="page-container__pricing">
        <div className="page-container__pricing__desc">
          <h2>Pricing</h2>
          <div>
            <p>
              Dataster offers a straightforward pricing model based on a
              metering mechanism. Users purchase prepaid credit in $25
              increments. Some features and functionalities rely on our Dataster
              LLMs; users may also send requests to our Dataster LLMs or to
              their own LLMs. Dataster meters input and output tokens and
              charges a small fee against your prepaid credit, based on actual
              consumption. Charges against your credit vary depending on the
              provider and model used. The detailed price table can be found
              below. Pricing may change.
            </p>
          </div>
        </div>

        <div className="page-container__pricing__desc">
          <h2>Try for free</h2>
          <div>
            <p>
              New users receive a $1 free tier credit upon signup, allowing them
              to test all features and functionalities. No credit card is
              required.
            </p>
          </div>
        </div>

        <div className="page-container__pricing__desc">
          <h2>Price table for Completions</h2>
          <p>For 1M tokens.</p>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <table class="pretty-table">
                <thead>
                  <tr>
                    <th>Provider</th>
                    <th>Model</th>
                    <th>Dataster Input</th>
                    <th>Dataster Output</th>
                    <th>BYO Input*</th>
                    <th>BYO Output*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Azure</td>
                    <td>GPT-3.5-Turbo-0125</td>
                    <td>$0.90</td>
                    <td>$2.70</td>
                    <td>$0.30</td>
                    <td>$0.90</td>
                  </tr>

                  <tr>
                    <td>Azure</td>
                    <td>GPT-4o-2024-05-13</td>
                    <td>$9.00</td>
                    <td>$27.00</td>
                    <td>$3.00</td>
                    <td>$9.00</td>
                  </tr>

                  <tr>
                    <td>Azure</td>
                    <td>GPT-4o-2024-08-06</td>
                    <td>$4.95</td>
                    <td>$19.80</td>
                    <td>$1.65</td>
                    <td>$6.60</td>
                  </tr>

                  <tr>
                    <td>AWS</td>
                    <td>Claude 3 Sonnet</td>
                    <td>$5.40</td>
                    <td>$27.00</td>
                    <td>$1.80</td>
                    <td>$9.00</td>
                  </tr>

                  <tr>
                    <td>AWS</td>
                    <td>Llama 3 Instruct (8B)</td>
                    <td>$0.54</td>
                    <td>$1.08</td>
                    <td>$0.18</td>
                    <td>$0.36</td>
                  </tr>

                  <tr>
                    <td>Azure</td>
                    <td>text-embedding-3-small</td>
                    <td>$0.04</td>
                    <td>N/A</td>
                    <td>$0.01</td>
                    <td>N/A</td>
                  </tr>

                  <tr>
                    <td>Azure</td>
                    <td>text-embedding-3-large</td>
                    <td>$0.23</td>
                    <td>N/A</td>
                    <td>$0.08</td>
                    <td>N/A</td>
                  </tr>

                  <tr>
                    <td>Azure</td>
                    <td>GPT-4o-mini-2024-07-18</td>
                    <td>$0.30</td>
                    <td>$1.19</td>
                    <td>$0.10</td>
                    <td>$0.40</td>
                  </tr>

                  <tr>
                    <td>AWS</td>
                    <td>Claude 3 Haiku</td>
                    <td>$0.45</td>
                    <td>$2.25</td>
                    <td>$0.15</td>
                    <td>$0.75</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              *Amounts in the BYO columns represent only the Dataster charge for
              the requests sent to a user provided LLM. Users may be charged
              additional fees by their providers.
            </p>
          </div>
        </div>

        <div className="page-container__pricing__desc">
          <h2>Other Capabilities</h2>

          <p>
            The dataster built-in capabilities are billed as follows for 1M
            tokens.
          </p>
          <div>
            <table class="pretty-table">
              <thead>
                <tr>
                  <th>Capability</th>
                  <th>Input</th>
                  <th>Output</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Critique</td>
                  <td>$9.00</td>
                  <td>$27.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="page-container__pricing__desc">
          <h2>Refunds</h2>
          <div>
            <p>
              Prepaid credit is purchased in increments of $25. Dataster cannot
              refund partially used increments. However, Dataster will refund
              unused increments minus a 10% fee to cover third-party payment
              processing costs.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
