import React from "react";
import ImageGallery from "../imageGallery/imageGallery";

export default function Solutions(props) {
  const { onClick } = props;
  return (
    <div className="page-container" onClick={onClick}>
      <div className="page-container__item">
        <ImageGallery image="/promptGallery.jpg" alt="prompt gallery" />
        <div className="page-container__item__desc">
          <h2>PROMPT GALLERY</h2>
          <div>
            <p>
              Dataster provides a feature-rich prompt gallery that supports
              system prompts, user prompts, and assistant prompts. Organize your
              prompts by use case, and evaluate them individually or together.
              Use them in the playground with one click, or use a prompt set to
              determine the average latency of an LLM for your use case.
            </p>
          </div>
        </div>
      </div>

      <div className="page-container__item">
        <ImageGallery image="/critique.jpg" alt="prompt critique" />
        <div className="page-container__item__desc">
          <h2>ITERATIVE IMPROVEMENT</h2>
          <div>
            <p>
              Dataster suggests recommendations for your system prompts, helping
              you spot prompt engineering best practices that may have been
              missed. Additionally, Dataster provides a grade with each
              iteration, ensuring your iterations move your application in the
              right direction.
            </p>
          </div>
        </div>
      </div>

      <div className="page-container__item">
        <ImageGallery image="/racetrack.jpg" alt="race track" />
        <div className="page-container__item__desc">
          <h2>LLM RACETRACK</h2>
          <div>
            <p>
              Take your LLMs to the racetrack and evaluate their respective
              latencies on your prompts. Dataster allows you to have up to six
              LLMs compete for the lowest latency against the same prompt.
            </p>
          </div>
        </div>
      </div>

      <div className="page-container__item">
        <ImageGallery image="/latencyjob.jpg" alt="latency job" />
        <div className="page-container__item__desc">
          <h2>LATENCY JOBS</h2>
          <div>
            <p>
              Systematically run your entire use case against various
              combinations of LLMs and system prompts. Determine which
              combination is best for your use case and will bring the lowest
              latency to your end users.
            </p>
          </div>
        </div>
      </div>

      <div className="page-container__item">
        <ImageGallery image="/humanEval.jpg" alt="latency job" />
        <div className="page-container__item__desc">
          <h2>EVALUATION JOBS</h2>
          <div>
            <p>
              Systematically run your entire use case against various
              combinations of LLMs and system prompts. Determine which
              combination is best for your use case and will bring the lowest
              latency to your end users.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
