import React from "react";

export default function Privacy(props) {
  const { onClick } = props;

  return (
    <div class="WordSection1" onClick={onClick}>
      <div>
        <p class="MsoTitle">Privacy Policy for Dataster</p>
      </div>

      <h2>1. Introduction</h2>

      <p class="MsoNormal">
        Welcome to Dataster. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you use our SaaS
        application designed to help developers build generative AI
        applications. By using Dataster, you agree to the collection and use of
        information in accordance with this policy.
      </p>

      <h2>2. Data Collection</h2>

      <p class="MsoNormal">
        We collect the following types of personal data from users:
        <br />
        - Name
        <br />
        - Email Address (used as user ID)
        <br />
        <br />
        This information is collected during account creation.
        <br />
        <br />
        Additionally, we collect user activity data, such as login records and
        payment processing details, to understand our users better and tailor
        our services accordingly. We do not collect IP addresses or use cookies,
        except for a cookie containing an access token to provide application
        access.
      </p>

      <p class="MsoNormal">
        Users may elect to save application data such as but not limited to
        prompts, large language models (LLMs) and their access credentials.
      </p>

      <h2>3. Use of Data</h2>

      <p class="MsoNormal">
        We use the collected user data for the following purposes:
        <br />
        - To identify and authenticate users.
        <br />
        - To communicate with users in case of abuse, problems, or policy
        changes.
        <br />- To notify users about new features and capabilities.
      </p>

      <p class="MsoNormal">
        We use the collected user activity data for the following purposes:
        <br />
        - To troubleshoot problems.
        <br />- To understand application bottlenecks and provide a better
        service.
      </p>

      <p class="MsoNormal">
        We use the collected application data for the following purposes:
        <br />- To provide advanced features and capabilities.
      </p>

      <h2>4. Data Sharing</h2>

      <p class="MsoNormal">
        We do not share any personally identifiable information, such as
        username, name, or email address, with third parties.
      </p>

      <h2>5. Data Storage and Security</h2>

      <p class="MsoNormal">
        User data is stored in a well-known third-party cloud database, which
        provides robust encryption to protect data in-transit, at-rest, and
        in-use. We take appropriate measures to ensure the security of user data
        throughout its lifecycle.
      </p>

      <h2>6. User Rights</h2>

      <p class="MsoNormal">
        Users have the right to:
        <br />
        - Access their data by requesting it through the contact form. We will
        email the data to the user ID email within 7 business days.
        <br />- Delete their account and all associated data through the
        settings section. Data will be deleted immediately upon account
        deletion.
      </p>

      <h2>7. Data Retention</h2>

      <p class="MsoNormal">
        We retain user data until the user deletes their account. Once an
        account is deleted, all associated data is permanently removed from our
        systems.
      </p>

      <h2>8. Cookies and Tracking Technologies</h2>

      <p class="MsoNormal">
        We use a cookie containing an access token to grant users access to the
        application. We do not use any additional cookies or tracking
        technologies at this time.
      </p>

      <h2>9. Third-Party Services</h2>

      <p class="MsoNormal">
        We use the following third-party services:
        <br />
        - Payment Processing: Handled by Stripe.
        <br />- Email Services: Emails are sent using Amazon Web Services (AWS).
      </p>

      <h2>10. Data Breach Notification</h2>

      <p class="MsoNormal">
        In the event of a data breach, we will notify all affected users by
        email and provide details about the data that may have been exposed.
      </p>

      <h2>11. Compliance with Laws</h2>

      <p class="MsoNormal">
        Currently, Dataster does not comply with GDPR or CCPA. We are committed
        to ensuring the privacy and security of user data and are working
        towards compliance with relevant regulations.
      </p>

      <h2>12. Changes to Privacy Policy</h2>

      <p class="MsoNormal">
        We may update our Privacy Policy from time to time. We will notify users
        of any changes by posting the new Privacy Policy on this page and
        updating the 'Last updated' date at the top.
      </p>

      <h2>13. Contact Information</h2>

      <p class="MsoNormal">
        For questions or concerns about this Privacy Policy, users can contact
        us via the contact form in the settings section of the application or
        directly at contact@dataster.com.
      </p>

      <h2>14. Children's Privacy</h2>

      <p class="MsoNormal">
        Dataster is not intended for use by individuals under the age of 13. We
        do not knowingly collect personal information from children under 13. If
        we become aware that we have collected personal information from a child
        under age 13 without verification of parental consent, we will take
        steps to delete that information.
      </p>
    </div>
  );
}
