import React from "react";

export default function About(props) {
  const { onClick } = props;
  return (
    <div className="page-container" onClick={onClick}>
      <div className="page-container__about">
        <div className="page-container__about__desc">
          <h2>Who we are</h2>
          <div>
            <p>
              Dataster, LLC is a tech startup based in New York City, NY. We are
              developers and builders passionate about artificial intelligence
              in general, and generative AI in particular. We serve developers,
              both professional and hobbyist, as well as application owners.
            </p>
          </div>
        </div>
        <div className="page-container__about__desc">
          <h2>Our mission</h2>
          <div>
            <p>
              At Dataster, we aim to enable developers to build better AI
              applications and build them faster. We believe that developers
              should be free to use the best tool for a given job without
              lock-in or constraint. In the context of AI, this means having
              equally easy access to various models, whether self-hosted or
              hosted by cloud providers. Dataster strives to provide development
              and GenAIOps tools that span a large catalog of models and
              providers, allowing application owners and developers to make the
              best decisions for their use cases and go to market faster.
            </p>
          </div>
        </div>

        <div className="page-container__about__desc">
          <h2>Contact</h2>
          <div>
            <p>contact@dataster.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}
