import React from "react";
import { NavLink } from "react-router-dom";
import HamburgerIcon from "../ui/hamburger/hamburger";

export default function Nav(props) {
  const { onHamburgerClick } = props;
  return (
    <header className="header">
      <div className="logo">
        <img src="/headerLogoWhite2.png" alt="logo" />
      </div>
      <nav className="menu">
        <ul>
          <li>
            <NavLink
              to="/solutions/"
              end
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Solutions
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/about/"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              About
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/pricing/"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Pricing
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/news/"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              News
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/privacy/"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Privacy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/terms/"
              className={({ isActive }) => (isActive ? "active-link" : "")}
            >
              Terms
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="cta">
        <div className="signup">
          <a href="https://app.dataster.com/signup">Try Free</a>
        </div>
        <div>
          <a href="https://app.dataster.com/login">Sign In</a>
        </div>
      </div>
      <div className="hamburger">
        <button onClick={onHamburgerClick}>
          <HamburgerIcon />
        </button>
      </div>
    </header>
  );
}
