export default function About(props) {
  const { onClick } = props;
  return (
    <div className="page-container" onClick={onClick}>
      <div className="page-container__about">
        <div className="page-container__about__desc">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2>User Prompt Ground Truth</h2>
            <span>10/08/2024</span>
          </div>
          <div>
            <p>
              In Today's release, we are adding the capability to optionally
              save a ground truth answer corresponding to user question in a
              user prompt. This will underpin our upcoming{" "}
              <strong>Auto Eval</strong> feature which will let you launch
              automated evaluation jobs to determine how your RAGs perform
              against a large and representative set of user questions. This
              will supplement the existing <strong>Human Eval</strong> feature
              which lets users manually assign grades to each response and rank
              their models and RAGs for a specific use case.
            </p>
          </div>
        </div>
        <div className="page-container__about__desc">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2>OpenAI on Dataster</h2>
            <span>10/07/2024</span>
          </div>
          <div>
            <p>
              Today, we add support for <code>gpt-4o-2024-08-06</code> hosted on
              OpenAI. This model was previously already supported on Dataster
              but only as an Azure OpenAI hosted model. Users can now compare
              the model performance across both providers. With this release,
              Dataster now supports 10 models across three different providers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
